<template>
  <div v-if="order">
    <van-notice-bar
      v-if="reminder"
      class="mb-4"
      wrapable
      :scrollable="false"
      :text="reminder"
    />
    <div v-if="!needPay" class="bg-white mb-4 p-8 flex flex-col items-center">
      <div class="text-base mb-4 text-gray-700 font-medium">取餐号</div>
      <div class="text-3xl font-bold">{{ order.SaleMain.quan_id }}</div>
    </div>
    <van-cell-group>
      <van-cell title="订单状态">
        {{ status }}
      </van-cell>
      <template v-if="$store.state.date">
        <van-cell title="餐次">
          {{ order.date }} {{ order.orderType }}
        </van-cell>
        <van-cell title="床位号">
          {{ order.bed }}
        </van-cell>
      </template>
    </van-cell-group>
    <van-cell-group class="my-4">
      <div
        class="px-4 py-2"
        v-for="food in order.SaleDetails"
        :key="food.code + food.kw"
      >
        <div class="font-semibold text-sm">{{ food.name }}</div>
        <div class="text-xs text-gray-500">{{ food.kw | getKouWei }}</div>
        <div class="flex items-center justify-between mt-1">
          <div class="font-medium text-sm">￥{{ food.real_outprice }}</div>
          <div class="text-xs text-gray-500">x{{ food.qty }}</div>
        </div>
      </div>
    </van-cell-group>
    <van-cell-group class="my-4">
      <van-cell title="备注" :value="order.SaleMain.memo" />
    </van-cell-group>
    <van-cell-group>
      <van-cell title="合计">
        <span class="font-bold text-black">
          ￥{{ order.SaleMain.pay_total }}
        </span>
      </van-cell>
    </van-cell-group>
    <van-submit-bar v-if="needPay" :price="order.SaleMain.pay_total * 100">
      <template #button>
        <van-button
          class="ml-2 submit-button"
          round
          type="danger"
          @click="() => pay()"
          :loading="submitting"
          >立即支付</van-button
        >
      </template>
    </van-submit-bar>
  </div>
</template>

<script>
import { getOrder, getReminder } from '@/api'
import { Toast } from 'vant'
import wechatPay from '@/wcpay'

const genWsaleId = order => {
  const sale_id = order.SaleMain.sale_id
  const wsale_id = order.SaleMain.wsale_id
  if (!wsale_id) {
    return `${sale_id}0`
  }
  const step = wsale_id.replace(sale_id, '')
  return `${sale_id}${Number(step) + 1}`
}

export default {
  data() {
    return {
      order: null,
      submitting: false,
      reminder: '',
      paid: false
    }
  },

  computed: {
    needPay() {
      const status = this.order.SaleMain.yyd
      return !status || status === 'UNPAY'
    },
    status() {
      const status = this.order.SaleMain.yyd
      if (!status || status === 'UNPAY') {
        return '等待支付'
      }
      return '已支付'
    }
  },

  filters: {
    getKouWei(kw) {
      if (!kw) {
        return null
      }
      return kw
        .replace(/^</, '')
        .replace(/>$/, '')
        .split('><')
        .join('/')
    }
  },

  methods: {
    async pay() {
      this.submitting = true
      this.paid = true
      try {
        const wsale_id = genWsaleId(this.order)
        const { lsale_id, pay_total } = this.order.SaleMain
        await wechatPay(
          {
            lsale_id,
            pay_total,
            wsale_id,
            sale_id: this.order.SaleMain.sale_id
          },
          this.fetchOrder
        )
      } catch (err) {
        console.log(err)
      } finally {
        this.submitting = false
      }
    },

    fetchOrder() {
      const sale_id = this.$route.query.sale_id
      getOrder({
        sale_id
      })
        .then(order => {
          this.order = order
          if (this.$route.query.afterCheckout && !this.paid) {
            this.pay()
          }
        })
        .catch(err => {
          Toast(err)
        })
    }
  },

  created() {
    getReminder().then(data => {
      this.reminder = data[0].memo
    })
  },

  mounted() {
    this.fetchOrder()
  }
}
</script>
<style lang="scss">
.submit-button {
  width: 110px;
  height: 40px;
}
</style>