import { Toast, Dialog } from 'vant'
import { pay, updateOrder } from './api'
const PAY_ID_KEY = 'PAY_ORDER_ID' + window.CONFIG.appId

export const setPayOrderId = id => {
  localStorage.setItem(PAY_ID_KEY, id)
}

export const getPayOrderId = () => {
  const id = localStorage.getItem(PAY_ID_KEY)
  localStorage.removeItem(PAY_ID_KEY)
  return id
}

function wechatPay(res, callback) {
  function onBridgeReady() {
    // eslint-disable-next-line
    WeixinJSBridge.invoke(
      'getBrandWCPayRequest',
      {
        appId: res.payAppId, //公众号ID，由商户传入
        timeStamp: res.payTimeStamp, //时间戳，自1970年以来的秒数
        nonceStr: res.paynonceStr, //随机串
        package: res.payPackage,
        signType: res.paySignType, //微信签名方式：
        paySign: res.paySign //微信签名
      },
      function(res) {
        if (res.err_msg == 'get_brand_wcpay_request:ok') {
          // 使用以上方式判断前端返回,微信团队郑重提示：
          //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          callback(true)
        } else {
          callback(false)
        }
      }
    )
  }
  if (typeof WeixinJSBridge == 'undefined') {
    if (document.addEventListener) {
      document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false)
    } else if (document.attachEvent) {
      document.attachEvent('WeixinJSBridgeReady', onBridgeReady)
      document.attachEvent('onWeixinJSBridgeReady', onBridgeReady)
    }
  } else {
    onBridgeReady()
  }
}

export default async function payOrder(params, callback) {
  const { lsale_id, pay_total, wsale_id, sale_id } = params
  const res = await pay({
    ordNo: wsale_id,
    amt: pay_total,
    payType: 'WECHAT',
    payWay: '02',
    subject: '订单标题',
    timeExpire: '20',
    userId: lsale_id
  })

  if (res.respData) {
    await updateOrder({
      sale_id,
      payid: res.respData.sxfUuid,
      wsale_id
    })
    wechatPay(res.respData, async success => {
      if (success) {
        await Dialog({
          message: '支付成功！'
        })
      } else {
        await Dialog({
          message: '未支付'
        })
      }
      callback(true)
    })
  } else {
    Toast({
      message: res.msg
    })
    callback(false)
  }
}
